import styled from 'styled-components';
import Button from './Button';

//construct Button Component with styled
export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => props.width || '100%'};
  cursor: pointer;
  background: transparent;
  color: ${(props) => props.color || 'rgb(61, 176, 153)'};
  outline: none;
  font-size: 1.2rem;
  padding: 5px 20px;
  margin: 2rem 0rem 4rem 0;
  box-shadow: 1px 1px 2px rgb(61, 176, 153);
  border: 1px solid rgba(61, 176, 153, 1);
  border-radius: 9999px;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    background: ${(props) => props.bg || 'rgba(61, 176, 153, 1)'};
    color: white;
    box-shadow: 2px 2px 4px rgb(61, 176, 153);
    text-shadow: 2px 2px 5px #2f8675;
    transform: translateY(-3px);
  }
`;
